.tab {
  position: relative;
  border: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4)) !important;
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6)) !important;
  padding: 6px 10px 6px 10px;
  font-weight: 600;
  font-size: 14px;
  &:first-of-type {
    border-radius: 6px 0 0 6px;
  }

  &:last-of-type {
    border-radius: 0 6px 6px 0;
  }

  & + & {
    border-left-width: 0;
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5)) !important;
  }

  @media (max-width: 429px) {
    &:last-of-type {
      border-radius: 6px;
      margin-top: 10px;
    }
    &:nth-last-of-type(2) {
      border-radius: 0 6px 6px 0;
    }
  }

  &[data-active] {
    z-index: 1;
    background-color: #e0e7ff !important;
    border-color: #4f46e5 !important;
    color: #4f46e5 !important;
    border: 2px solid !important;
  }
}
